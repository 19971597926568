import React from 'react';
import {
  responsiveFontSizes,
  Typography,
  ThemeProvider,
  createTheme,
  Box,
  Paper,
  Grid,
  Button, useMediaQuery, useTheme,
} from '@mui/material';
import {
  AirplaneTicketOutlined, ConnectingAirports,
  Event, LuggageOutlined, PsychologyAltOutlined,
  SettingsEthernet, SupportAgentOutlined,
} from '@mui/icons-material';
import { trackPageview } from './tracking/tracking';

const theme = responsiveFontSizes(createTheme({
  palette: {
    primary: { main: '#429F9F' },
    secondary: { main: '#28235C' },
  },
  typography: {
    fontFamily: 'Ubuntu, sans-serif',
    fontWeightLight: 300,
    fontWeightRegular: 300,
    fontWeightBold: 400,
    fontWeightMedium: 300,
  }
}));

const Root = () => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  React.useEffect(() => {
    trackPageview()
  }, []);

  return (
    <Box sx={{ textAlign: 'center', py: 4, px: 0 }}>
      <img src="logoFull.png" alt="Logo" style={{ width: isMobile ? 100 : 150, height: 'auto' }}/>
      <Box sx={{ mt: 6, backgroundColor: t => t.palette.primary.main, pb: 3 }}>
        <Box sx={{ mb: [0, -7] }}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path
              fill="#fff"
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            />
          </svg>
        </Box>
        <Typography variant="h2" component="h4" color="#fff" fontWeight={600}>
          Finally!
        </Typography>
        <Typography variant="h4" component="h6" color="#fff" sx={{ p: [2, 0] }}>
          A whitelabel airline chatbot your customers will want to talk to!
        </Typography>
      </Box>
      <Box sx={{ px: 4, pb: 2, backgroundColor: t => t.palette.primary.main }}>
        <iframe
          style={{ width: '100%', height: 'calc(100vw / 2)', maxWidth: 1000, maxHeight: 562 }}
          src="https://www.youtube.com/embed/bSrGv7GOcIc"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
      </Box>
      <Box sx={{ backgroundColor: t => t.palette.primary.main, pb: 4 }}>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          endIcon={<Event/>}
          href="https://meetings.hubspot.com/my11/captain-aidan"
          target="_blank"
          rel="noreferrer noopener"
          sx={{ mt: 2 }}
        >
          Book a Call
        </Button>
      </Box>
      <Box sx={{ background: t => t.palette.secondary.main }}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path
            fill="#429F9F"
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
          />
        </svg>
      </Box>
      <Box p={4} pb={18} pt={0} sx={{ background: t => t.palette.secondary.main }}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h3" color="#fff" sx={{ mt: [2, 4], mb: 2 }}>What can he do?</Typography>
          </Grid>
          <Grid item xs={12}>
            <Box component={Paper} elevation={0} p={3}>
              <Typography variant="h6" fontWeight={400} color="secondary">Works as a White-Label, Stand-alone, Web, Whatsapp or <b>ChatGPT Plugin.</b></Typography>
            </Box>
          </Grid>
          {[
            [AirplaneTicketOutlined, 'Book Flights', 'Captain Aidan can seamlessly and naturally help your customers book flights as well as change them. He is also very good at upselling ancillaries to customers.'],
            [LuggageOutlined, 'Modify Bookings', 'Customers can change their reservation dates, upgrade class, buy ancillaries like check-in bags, priority boarding, extra legroom and much more.'],
            [ConnectingAirports, 'Check Status & Weather', 'Aidan can help customers get your up-to-date information about their flight as well what to expect for takeoff, cruising, landing and much more.'],
            [SupportAgentOutlined, 'Faster & Better Support', 'Captain Aidan uses your own FAQ and information to provide comprehensive, extremely customised and relevant answers to your customers’ questions.'],
            [SettingsEthernet, 'Integrate with Your Data', 'Captain Aidan can be integrated with your APIs, website, RM and much more. Let your customers quickly access the latest and most relevant information.'],
            [PsychologyAltOutlined, 'Reduce Fear of Flying', 'Anxious passengers can have someone to talk to about their concerns. Captain Aidan will be more than happy to support the passenger and help them reduce their fears.'],
          ].map(([Icon, title, description]: any, i) => (
            <Grid item xs={12} sm={4} key={i}>
              <Box component={Paper} elevation={0} p={3}>
                <Icon style={{ width: 80, height: 80 }} color="secondary"/>
                <Typography variant="h5" fontWeight={400} color="secondary">{title}</Typography>
                <Typography>
                  {description}
                </Typography>
              </Box>
            </Grid>
          ))}
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              endIcon={<Event/>}
              href="https://meetings.hubspot.com/my11/captain-aidan"
              target="_blank"
              rel="noreferrer noopener"
              sx={{ mt: 2, mb: -6 }}
            >
              Book a Call
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ overflow: 'hidden' }}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none" style={{ width: '140%' }}>
          <path
            fill="#28235C"
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
          />
        </svg>
      </Box>
      <Typography align="center" variant="subtitle2" sx={{ mt: [2, -8], mb: 4, '& a:link, & a:visited ': { color: '#000' } }}>
        <a href="https://www.mentourpilot.com" target="_blank" rel="noopener noreferrer"><img src="mpLogo.png" alt="MP Logo" style={{ width: 240, height: 'auto' }}/></a><br/>
        <a href="https://www.cvt.ai/captainaidan" target="_blank" rel="noopener noreferrer"><img src="cvtLogo.png" alt="CVT Logo" style={{ width: 90, height: 'auto', marginBottom: 40 }}/></a><br/>
        &copy; 2023 - Captain Aidan <br/>
        a product developed by <a href="https://www.cvt.ai/captainaidan" target="_blank" rel="noopener noreferrer">CVT</a> & <a href="https://www.mentourpilot.com" target="_blank" rel="noopener noreferrer">Mentour 360</a>
      </Typography>
    </Box>
  );
}

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Root/>
    </ThemeProvider>
  )
}

export default App;

import { getAnalytics, logEvent } from 'firebase/analytics';

import { app } from '../clients/firebaseClient';

const analytics = getAnalytics(app);

export const trackPageview = () => {
  logEvent(analytics, 'page_view', {
    page_path: window.location.pathname,
    page_title: document.title,
    page_location: window.location.href,
  });
};
